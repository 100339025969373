export const getCSRFToken = () => {
  const name = 'csrftoken';
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

export const fetchCSRFToken = async () => {
  const response = await fetch('https://portal.coremedhealth.com/api/get-csrf-token/', {
    credentials: 'include',
  });
  if (response.ok) {
    return getCSRFToken();
  }
  throw new Error('Failed to fetch CSRF token');
};
