import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Button from './Button';
import './Header.css';
import image1 from '../assets/images/logo.png';

const Header = () => {
  const { isAuthenticated, userType, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <header className="header">
      {/* Always display the logo */}
      <div className="logo-container">
        <img src={image1} alt="Provider Portal Logo" className="logo" />
        <span className="logo-text">COREMED 360</span>
      </div>

      {/* Conditionally display the menu items based on authentication */}
      {isAuthenticated && (
        <nav>
          <ul className="nav-links">
            <li><Link to="/home">Home</Link></li>
            {userType === 'CoreMed Admin' && (
              <li className="dropdown">
                <span>Core Admin</span>
                <ul className="dropdown-content">
                  <li><Link to="/provider-roster">Provider Roster</Link></li>
                  <li><Link to="/utilization-roster">Utilization Roster</Link></li>
                  <li><Link to="/member-roster">Member Roster</Link></li>
                  <li><Link to="/pricing-roster">Pricing Roster</Link></li>
                  <li><Link to="/user-management">User Management</Link></li>
                </ul>
              </li>
            )}
            {/* External links for Core Benefits and Core Memberships */}
            <li><a href="https://www.coremedhealth.com/" target="_blank" rel="noopener noreferrer">Core Benefits</a></li>
            <li><a href="https://www.coremedhealth.com/pages/membership-overview" target="_blank" rel="noopener noreferrer">Core Memberships</a></li>
            <li><Link to="/user-profile">User Profile</Link></li>
            <li><Button onClick={handleLogout} className="logout-button" gradient>Logout</Button></li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;
