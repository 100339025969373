import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import './Layout.css';

const Layout = ({ children, onMenuItemClick }) => {
  return (
    <div>
      <Header />
      <div className="layout-container">
        <Sidebar onMenuItemClick={onMenuItemClick} />
        <div className="content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
