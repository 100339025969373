import React, { createContext, useReducer, useContext, useEffect, useMemo } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const initialState = {
  isAuthenticated: false,
  userType: '',
  providerName: '',
  firstName: '',
  lastName: ''
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        isAuthenticated: true,
        userType: action.payload.userType,
        providerName: action.payload.providerName,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName
      };
    case 'LOGOUT':
      return initialState;
    case 'SET_AUTH':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        userType: action.payload.userType,
        providerName: action.payload.providerName,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName
      };
    default:
      return state;
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const storedAuthState = localStorage.getItem('isAuthenticated') === 'true';
    const storedUserType = localStorage.getItem('userType');
    const storedProviderName = localStorage.getItem('providerName');
    const storedFirstName = localStorage.getItem('firstName');
    const storedLastName = localStorage.getItem('lastName');

    if (storedAuthState) {
      dispatch({
        type: 'SET_AUTH',
        payload: {
          isAuthenticated: true,
          userType: storedUserType || '',
          providerName: storedProviderName || '',
          firstName: storedFirstName || '',
          lastName: storedLastName || ''
        }
      });
    } else {
      dispatch({ type: 'LOGOUT' });
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!sessionStorage.getItem('isRefresh')) {
        // If the page is not being refreshed, clear localStorage (for closing tab/window)
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('userType');
        localStorage.removeItem('providerName');
        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
      } else {
        sessionStorage.removeItem('isRefresh'); // Clear refresh flag after refresh
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // Detect page refresh and store a flag in sessionStorage
    const handlePageRefresh = () => {
      sessionStorage.setItem('isRefresh', 'true');
    };

    window.addEventListener('unload', handlePageRefresh);

    return () => {
      window.removeEventListener('unload', handlePageRefresh);
    };
  }, []);

  const login = ({ userType, providerName, firstName, lastName }) => {
    dispatch({ type: 'LOGIN', payload: { userType, providerName, firstName, lastName } });
    try {
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('userType', userType);
      localStorage.setItem('providerName', providerName);
      localStorage.setItem('firstName', firstName);
      localStorage.setItem('lastName', lastName);
    } catch (error) {
      console.error('Error saving to localStorage:', error);
    }
  };

  const logout = () => {
    dispatch({ type: 'LOGOUT' });
    try {
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('userType');
      localStorage.removeItem('providerName');
      localStorage.removeItem('firstName');
      localStorage.removeItem('lastName');
    } catch (error) {
      console.error('Error removing from localStorage:', error);
    }
  };

  const contextValue = useMemo(() => ({
    isAuthenticated: state.isAuthenticated,
    userType: state.userType,
    providerName: state.providerName,
    firstName: state.firstName,
    lastName: state.lastName,
    login,
    logout,
  }), [state.isAuthenticated, state.userType, state.providerName, state.firstName, state.lastName]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};
