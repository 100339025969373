import React from 'react';
import PropTypes from 'prop-types';
import './Button.css'; // Ensure this imports your CSS

const Button = ({ onClick, children, type = 'button', gradient = false }) => {
  const className = gradient ? 'custom-button gradient' : 'custom-button';

  return (
    <button className={className} onClick={onClick} type={type}>
      {children}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  gradient: PropTypes.bool,
};

export default Button;
