import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './Sidebar.css';

const Sidebar = () => {
  const navigate = useNavigate();
  const { userType } = useAuth();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <h2>Provider Portal Menu</h2>
      </div>
      <ul className="sidebar-menu">
        <li>
          <span
            role="link"
            tabIndex="0"
            className="menu-item-link"
            onClick={() => handleNavigation('/check-eligibility')}
          >
            Check Eligibility
          </span>
        </li>
        <li>
          <span
            role="link"
            tabIndex="0"
            className="menu-item-link"
            onClick={() => handleNavigation('/add-utilization')}
          >
            Add Utilization
          </span>
        </li>
        {userType === 'Provider User' && (
          <li>
            <span
              role="link"
              tabIndex="0"
              className="menu-item-link"
              onClick={() => handleNavigation('/provider-users')}
            >
              View Users
            </span>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
