import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../utils/axios';  // Import your pre-configured axios instance

const AutoLogout = ({ timeout = 600000 }) => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    let timer;

    const resetTimer = () => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(logoutUser, timeout);
    };

    const handleActivity = () => resetTimer();

    const logoutUser = () => {
      logout();  // Call the logout method from AuthContext to clear the auth state

      axiosInstance.post('/api/logout/')  // Use axios to make the POST request
        .then(() => {
          navigate('/');  // Redirect to the login page after logout
        })
        .catch(error => {
          console.error('Error logging out:', error);
        });
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);

    resetTimer();

    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      if (timer) clearTimeout(timer);
    };
  }, [navigate, logout, timeout]);

  return null;
};

export default AutoLogout;
