import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
import Header from './Header';
import { useAuth } from '../context/AuthContext';
import './Navbar.css';

const Navbar = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = async () => {
    try {
      const response = await axios.post('/api/logout/');
      if (response.data.status === 'success') {
        logout();
        navigate('/');
      }
    } catch (error) {
      console.error('Logout failed', error);
      alert('Logout failed');
    }
  };

  return (
    <div>
      <Header />
      <button className="logout-button" onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Navbar;
